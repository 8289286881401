<template>
  <div>
    <v-row>
      <v-col
        md="12"
        sm="12"
        cols="12"
        class="mb-0 pt-0"
      >
        <template>
          <v-tabs vertical>
            <v-tab>
              <v-icon left>
                {{ icons.mdiAccountOutline }}
              </v-icon>
              Teams
            </v-tab>
            <v-tab>
              <v-icon left>
                {{ icons.mdiLaptop }}
              </v-icon>
              PC & Gear
            </v-tab>
            <v-tab>
              <v-icon left>
                {{ icons.mdiBeer }}
              </v-icon>
              Supplements
            </v-tab>

            <v-tab-item>
              <template>
                <v-tabs>
                  <v-tab>
                    wortex
                  </v-tab>
                  <v-tab>
                    LastWind
                  </v-tab>
                  <v-tab>
                    SC-eSports
                  </v-tab>
                  <v-tab>
                    x
                  </v-tab>
                  <v-tab>
                    x
                  </v-tab>
                  <v-tab>
                    x
                  </v-tab>
                  <v-tab-item>
                    <v-row class="  ">
                      <v-col
                        md="12"
                        sm="12"
                        cols="12"
                        class="mb-0 pb-0"
                      >
                        <v-img
                          width="100%"
                          height="350px"
                          src="/poster/wortex_cover.jpeg"

                          gradient="to top,  rgba(20, 20, 23,.7), rgba(20, 20, 23,.13)"
                        >
                          <v-card-title class="catalogue-title">
                          </v-card-title>
                        </v-img>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                        class="mr-0 pr-0"
                      >
                        <v-card
                          class="new-post-blog v-avatar-group"
                        >
                          <v-img
                            src="/shop/team-1/jersey-2.jpg"
                            class="new-post-blog no-br"
                            gradient="to top,  rgba(20, 20, 23,.1), rgba(20, 20, 23,.33)"
                          >
                            <div class="product-content-frame">
                              <v-card-title>
                                <a
                                  class="product-link"
                                  href="#"
                                >
                                  Long Sleeve Jumper <br><br> 99€
                                </a>
                                <br>
                              </v-card-title>
                              <v-card-text class="pb-0 mb-0">
                                Official WORTEX Player Jumper 2020
                              </v-card-text>
                            </div>
                          </v-img>
                        </v-card>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                        class="mr-0 pl-0 pr-0"
                      >
                        <v-card
                          class="new-post-blog v-avatar-group"
                        >
                          <v-img
                            src="/shop/team-1/jersey-1.jpg"
                            class="new-post-blog no-br"
                            gradient="to top,  rgba(20, 20, 23,.1), rgba(20, 20, 23,.33)"
                          >
                            <div class="product-content-frame">
                              <v-card-title>
                                <a
                                  class="product-link"
                                  href="#"
                                >
                                  Hoodie <br><br> 99€
                                </a>
                                <br>
                              </v-card-title>
                              <v-card-text class="pb-0 mb-0">
                                Official WORTEX Player Hoodie 2020
                              </v-card-text>
                            </div>
                          </v-img>
                        </v-card>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                        class="mr-0 pl-0 pr-0"
                      >
                        <v-card
                          class="new-post-blog v-avatar-group"
                        >
                          <v-img
                            src="/shop/team-1/shaker.jpg"
                            class="new-post-blog no-br"
                            gradient="to top,  rgba(20, 20, 23,.1), rgba(20, 20, 23,.33)"
                          >
                            <div class="product-content-frame">
                              <v-card-title>
                                <a
                                  class="product-link"
                                  href="#"
                                >
                                  shaker <br><br> 15€
                                </a>
                                <br>
                              </v-card-title>
                              <v-card-text class="pb-0 mb-0">
                                Official WORTEX shaker
                              </v-card-text>
                            </div>
                          </v-img>
                        </v-card>
                      </v-col>
                      <v-col
                        cols="12"
                        md="3"
                        class="mr-0 pl-0 pr-0"
                      >
                        <v-card
                          class="new-post-blog v-avatar-group"
                        >
                          <v-img
                            src="/shop/team-1/jersey-2.jpg"
                            class="new-post-blog no-br"
                            gradient="to top,  rgba(20, 20, 23,.1), rgba(20, 20, 23,.33)"
                          >
                            <div class="product-content-frame">
                              <v-card-title>
                                <a
                                  class="product-link"
                                  href="#"
                                >
                                  Long Sleeve Jumper <br><br> 99€
                                </a>
                                <br>
                              </v-card-title>
                              <v-card-text class="pb-0 mb-0">
                                Official WORTEX Player jumper 2020
                              </v-card-text>
                            </div>
                          </v-img>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <p>
                          Morbi nec metus. Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis tellus, vitae iaculis lacus elit id tortor. Sed mollis, eros et ultrices tempus, mauris ipsum aliquam libero, non adipiscing dolor urna a orci.
                        </p>

                        <p class="mb-0">
                          Suspendisse feugiat. Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis tellus, vitae iaculis lacus elit id tortor.
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <p>
                          Fusce a quam. Phasellus nec sem in justo pellentesque facilisis. Nam eget dui. Proin viverra, ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. In dui magna, posuere eget, vestibulum et, tempor auctor, justo.
                        </p>

                        <p class="mb-0">
                          Cras sagittis. Phasellus nec sem in justo pellentesque facilisis. Proin sapien ipsum, porta a, auctor quis, euismod ut, mi. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </template>
            </v-tab-item>
            <v-tab-item>
              <template>
                <v-tabs>
                  <v-tab>
                    Desktops
                  </v-tab>
                  <v-tab>
                    Keyboards
                  </v-tab>
                  <v-tab>
                    Headphones
                  </v-tab>
                  <v-tab>
                    Mouse
                  </v-tab>
                  <v-tab>
                    Monitors
                  </v-tab>
                  <v-tab-item>
                    <v-row class="pb-0">
                      <v-col
                        md="12"
                        sm="12"
                        cols="12"
                        class="mb-0 pb-0 pb-0"
                      >
                        <v-img
                          width="100%"
                          height="350px"
                          src="/shop/tech/promo-1.jpg"

                          gradient="to top,  rgba(20, 20, 23,.7), rgba(20, 20, 23,.13)"
                        >
                          <v-card-title class="catalogue-title">
                          </v-card-title>
                        </v-img>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                        class="mr-0 pr-1 pl-1"
                      >
                        <v-card
                          class="new-post-blog v-avatar-group"
                        >
                          <v-img
                            src="/shop/tech/ppnpsharkoon.png"
                            class="new-post-blog no-br"
                            gradient="to top,  rgba(20, 20, 23,.1), rgba(20, 20, 23,.33)"
                          >
                            <div class="product-content-frame">
                              <v-card-title>
                                <a
                                  class="product-link"
                                  href="#"
                                >
                                  FG PPNP SHARKOON <br><br> €1.649,00
                                </a>
                                <br>
                              </v-card-title>
                              <v-card-text class="pb-0 mb-0">
                                i3 12GEN / 16GB RAM / RTX 3060Ti
                              </v-card-text>
                            </div>
                          </v-img>
                        </v-card>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                        class="mr-0 pr-1 pl-1"
                      >
                        <v-card
                          class="new-post-blog v-avatar-group"
                        >
                          <v-img
                            src="/shop/tech/ppnploki.png"
                            class="new-post-blog no-br"
                            gradient="to top,  rgba(20, 20, 23,.1), rgba(20, 20, 23,.33)"
                          >
                            <div class="product-content-frame">
                              <v-card-title>
                                <a
                                  class="product-link"
                                  href="#"
                                >
                                  FG PPNP Loki  <br><br> €1.249,00
                                </a>
                                <br>
                              </v-card-title>
                              <v-card-text class="pb-0 mb-0">
                                i3 12GEN / 16GB RAM / RTX 3060
                              </v-card-text>
                            </div>
                          </v-img>
                        </v-card>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                        class="mr-0 pr-1 pl-1"
                      >
                        <v-card
                          class="new-post-blog v-avatar-group"
                        >
                          <v-img
                            src="/shop/tech/raven.png"
                            class="new-post-blog no-br"
                            gradient="to top,  rgba(20, 20, 23,.1), rgba(20, 20, 23,.33)"
                          >
                            <div class="product-content-frame">
                              <v-card-title>
                                <a
                                  class="product-link"
                                  href="#"
                                >
                                  FG PPNP RAVEN <br><br> €729,00
                                </a>
                                <br>
                              </v-card-title>
                              <v-card-text class="pb-0 mb-0">
                                i3 / 16GB RAM / GTX 1650
                              </v-card-text>
                            </div>
                          </v-img>
                        </v-card>
                      </v-col>
                    </v-row>
                    <v-row>
                      <v-col
                        cols="12"
                        md="4"
                        class="mr-0 pr-1 pl-1"
                      >
                        <v-card
                          class="new-post-blog v-avatar-group"
                        >
                          <v-img
                            src="/shop/tech/ppnpsharkoon.png"
                            class="new-post-blog no-br"
                            gradient="to top,  rgba(20, 20, 23,.1), rgba(20, 20, 23,.33)"
                          >
                            <div class="product-content-frame">
                              <v-card-title>
                                <a
                                  class="product-link"
                                  href="#"
                                >
                                  FG PPNP SHARKOON <br><br> €1.649,00
                                </a>
                                <br>
                              </v-card-title>
                              <v-card-text class="pb-0 mb-0">
                                i3 12GEN / 16GB RAM / RTX 3060Ti
                              </v-card-text>
                            </div>
                          </v-img>
                        </v-card>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                        class="mr-0 pr-1 pl-1"
                      >
                        <v-card
                          class="new-post-blog v-avatar-group"
                        >
                          <v-img
                            src="/shop/tech/ppnploki.png"
                            class="new-post-blog no-br"
                            gradient="to top,  rgba(20, 20, 23,.1), rgba(20, 20, 23,.33)"
                          >
                            <div class="product-content-frame">
                              <v-card-title>
                                <a
                                  class="product-link"
                                  href="#"
                                >
                                  FG PPNP Loki  <br><br> €1.249,00
                                </a>
                                <br>
                              </v-card-title>
                              <v-card-text class="pb-0 mb-0">
                                i3 12GEN / 16GB RAM / RTX 3060
                              </v-card-text>
                            </div>
                          </v-img>
                        </v-card>
                      </v-col>
                      <v-col
                        cols="12"
                        md="4"
                        class="mr-0 pr-1 pl-1"
                      >
                        <v-card
                          class="new-post-blog v-avatar-group"
                        >
                          <v-img
                            src="/shop/tech/raven.png"
                            class="new-post-blog no-br"
                            gradient="to top,  rgba(20, 20, 23,.1), rgba(20, 20, 23,.33)"
                          >
                            <div class="product-content-frame">
                              <v-card-title>
                                <a
                                  class="product-link"
                                  href="#"
                                >
                                  FG PPNP RAVEN <br><br> €729,00
                                </a>
                                <br>
                              </v-card-title>
                              <v-card-text class="pb-0 mb-0">
                                i3 / 16GB RAM / GTX 1650
                              </v-card-text>
                            </div>
                          </v-img>
                        </v-card>
                      </v-col>
                    </v-row>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <p>
                          Morbi nec metus. Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis tellus, vitae iaculis lacus elit id tortor. Sed mollis, eros et ultrices tempus, mauris ipsum aliquam libero, non adipiscing dolor urna a orci.
                        </p>

                        <p class="mb-0">
                          Suspendisse feugiat. Suspendisse faucibus, nunc et pellentesque egestas, lacus ante convallis tellus, vitae iaculis lacus elit id tortor.
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                  <v-tab-item>
                    <v-card flat>
                      <v-card-text>
                        <p>
                          Fusce a quam. Phasellus nec sem in justo pellentesque facilisis. Nam eget dui. Proin viverra, ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. In dui magna, posuere eget, vestibulum et, tempor auctor, justo.
                        </p>

                        <p class="mb-0">
                          Cras sagittis. Phasellus nec sem in justo pellentesque facilisis. Proin sapien ipsum, porta a, auctor quis, euismod ut, mi. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
                        </p>
                      </v-card-text>
                    </v-card>
                  </v-tab-item>
                </v-tabs>
              </template>
            </v-tab-item>
            <v-tab-item>
              <v-card flat>
                <v-card-text>
                  <p>
                    Fusce a quam. Phasellus nec sem in justo pellentesque facilisis. Nam eget dui. Proin viverra, ligula sit amet ultrices semper, ligula arcu tristique sapien, a accumsan nisi mauris ac eros. In dui magna, posuere eget, vestibulum et, tempor auctor, justo.
                  </p>

                  <p class="mb-0">
                    Cras sagittis. Phasellus nec sem in justo pellentesque facilisis. Proin sapien ipsum, porta a, auctor quis, euismod ut, mi. Donec quam felis, ultricies nec, pellentesque eu, pretium quis, sem.
                  </p>
                </v-card-text>
              </v-card>
            </v-tab-item>
          </v-tabs>
        </template>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import {
  mdiStarOutline,
  mdiCheckCircleOutline,
  mdiAccountOutline,
  mdiDotsHorizontal,
  mdiTrophy,
  mdiCurrencyEur,
  mdiTwitch,
  mdiCheckboxMarkedCircle,
  mdiLaptop,
  mdiBeer,
} from '@mdi/js'
import { required, emailValidator } from '@core/utils/validation'
import { VueRecaptcha } from 'vue-recaptcha'
import SocialStatsCard from '@/views/components/about/SocialStatsCard.vue'

export default {
  components: {
    VueRecaptcha,
  },
  setup() {
    return {
      SocialStatsCard,
      emailValidator,
      required,
      icons: {
        mdiStarOutline,
        mdiCurrencyEur,
        mdiCheckCircleOutline,
        mdiAccountOutline,
        mdiDotsHorizontal,
        mdiTrophy,
        mdiTwitch,
        mdiCheckboxMarkedCircle,
        mdiLaptop,
        mdiBeer,
      },
    }
  },
}
</script>
